.toast-demo button {
  min-width: 10rem;
  /* margin-right: 0.5rem; */
}

@media screen and (max-width: 960px) {
  .toast-demo button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.p-toast .p-component {
  margin: 0 25.7px 0 15.7px;
  opacity: 0.91 !important;
  mix-blend-mode: multiply;
  border-radius: 24px;
  /* border-radius: 11px; */
  -webkit-filter: blur(32px);
  filter: blur(32px);
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #d8dce3, #d8dce3);
}

.p-toast-message-success {
  border-width: 0 0 6px 0;
  background-color: #fff;
  color: #20cea5;
}

.p-toast-message-error {
  border-width: 0 0 6px 0;
  background-color: #fff;
  color: red;
}

.p-toast-message-text {
  padding-left: 14px;
}

.p-toast-message {
  opacity: 1;
  margin: 1px 0 25px;
  padding: 10px 0 0;
  border: solid 1px #ececec;
  background-color: #fff;
  color: black;
}

.Toastify__toast-container {
  display: block !important;
  position: fixed !important;
}

.p-toast-message-icon {
  display: none;
}

.p-toast-summary {
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
}

.p-toast-detail {
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  position: relative;
  bottom: 1.5rem;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  position: relative;
  bottom: 1.5rem;
}

.Toastify--animate-icon {
  display: none;
}

.Toastify__toast--success {
  border: solid;
  border-width: 0 0 6px 0;
  background-color: #fff;
  color: #20cea5;
  border-radius: 10px;
}

.Toastify__toast--error {
  border: solid;
  border-width: 0 0 6px 0;
  background-color: #fff;
  color: red;
  border-radius: 10px;
}

.Toastify {
  position: absolute;
  z-index: 999999;
}
